import ApiService from "@/core/services/ApiService";
import { Announcement } from "@/core/services/responsesModel/announcement"

// 取得空間上架中的公告
export function getPublishAnnouncements(spaceGuid: string): Promise<Array<Announcement>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Announcement/Publish`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得空間中所有草稿
export function getDraftAnnouncements(spaceGuid: string): Promise<Array<Announcement>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Announcement/Draft`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增草稿
export function addAnnouncements(spaceGuid: string, request): Promise<Announcement> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢指定公告
export function getTargetAnnouncement(spaceGuid: string, announcementGuid:string): Promise<Announcement> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Announcement/${announcementGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新草稿
export function updateDraft(spaceGuid: string, announcementGuid:string,request): Promise<Announcement> {
    return new Promise((resolve, reject) => {
        ApiService.put(`Space/${spaceGuid}/Announcement/${announcementGuid}`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 發佈公告
export function publishAnnouncement(spaceGuid: string, announcementGuid:string): Promise<Announcement> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/Publish`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 發佈公告通知
export function sendNotify(spaceGuid: string, announcementGuid:string,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/Notify`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 置頂公告
export function topAnnouncement(spaceGuid: string, announcementGuid:string) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/Top`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 解除置頂公告
export function untopAnnouncement(spaceGuid: string, announcementGuid:string) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/UnTop`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}


// 終止公告
export function terminateAnnouncement(spaceGuid: string, announcementGuid:string) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/Terminate`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 恢復公告
export function resumeAnnouncement(spaceGuid: string, announcementGuid:string) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Announcement/${announcementGuid}/Resume`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得歷史公告
export function getHistoryAnnouncement(spaceGuid: string): Promise<Array<Announcement>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Announcement/History`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
