
import { defineComponent, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import localstorage from "@/core/services/LocalstorageService";
import {
  getHistoryAnnouncement,
  resumeAnnouncement,
} from "@/core/services/api/announcement";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const currentItem: any = reactive([]);
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "標題",
        key: "title",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "起始時間",
        key: "beginTime",
        sortable: true,
      },
      {
        name: "結束時間",
        key: "endTime",
        sortable: true,
      },
      {
        name: "創建時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "操作",
        key: "operate",
        sortable: true,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };
    const isLoadind = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const setTableData = async () => {
      let historyAnnouncement = await getHistoryAnnouncement(currentSpaceGuid!);
      tableData.splice(0);
      historyAnnouncement.forEach((o) => {
        tableData.push({
          title: o.title,
          status: statusMap[o.status],
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
          guid: o.guid,
        });
      });
      initTableData.splice(0, initTableData.length, ...tableData);
      console.log("historyAnnouncement", historyAnnouncement);
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const toViewAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/viewAnnouncement/${currentItem.guid}`,
      });
    };

    const recover = async () => {
      await resumeAnnouncement(currentSpaceGuid!, currentItem.guid);
      await Swal.fire("公告已恢復!", "", "success");
      await setTableData();
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      setCurrentItem,
      currentItem,
      toViewAnnouncement,
      recover,
    };
  },
});
