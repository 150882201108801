<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-title="{ row: tableData }">
          {{ tableData.title }}
        </template>
        <template v-slot:cell-status="{ row: tableData }">
          {{ tableData.status }}
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toViewAnnouncement()"
                  >檢視公告</el-dropdown-item
                >
                <el-dropdown-item @click="recover()">恢復</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import localstorage from "@/core/services/LocalstorageService";
import {
  getHistoryAnnouncement,
  resumeAnnouncement,
} from "@/core/services/api/announcement";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const currentItem: any = reactive([]);
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "標題",
        key: "title",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "起始時間",
        key: "beginTime",
        sortable: true,
      },
      {
        name: "結束時間",
        key: "endTime",
        sortable: true,
      },
      {
        name: "創建時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "操作",
        key: "operate",
        sortable: true,
      },
    ]);
    const statusMap = {
      Draft: "草稿",
      Pending: "等待發佈",
      Publishing: "發佈中",
      Expired: "已過期",
      Termination: "已終止",
    };
    const isLoadind = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const setTableData = async () => {
      let historyAnnouncement = await getHistoryAnnouncement(currentSpaceGuid!);
      tableData.splice(0);
      historyAnnouncement.forEach((o) => {
        tableData.push({
          title: o.title,
          status: statusMap[o.status],
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          createTime: getLocalTimeString(o.createTime),
          guid: o.guid,
        });
      });
      initTableData.splice(0, initTableData.length, ...tableData);
      console.log("historyAnnouncement", historyAnnouncement);
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const toViewAnnouncement = () => {
      router.push({
        path: `/routineService/announcementManagement/viewAnnouncement/${currentItem.guid}`,
      });
    };

    const recover = async () => {
      await resumeAnnouncement(currentSpaceGuid!, currentItem.guid);
      await Swal.fire("公告已恢復!", "", "success");
      await setTableData();
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      setCurrentItem,
      currentItem,
      toViewAnnouncement,
      recover,
    };
  },
});
</script>

<style scoped>
</style>